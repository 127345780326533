import { Visualization } from 'components/Visualizer/visualization';
import { PeopleLiveDataToPeopleDraw } from '../util';
import WsClients from 'app/clients/wsClient';
import { LDM } from './liveDataManager';
import { PeopleDraw } from '_types';

export class UsersDataManager implements LDM {
    viz: Visualization;
    wsClient: WsClients;
    subscriptionStopped: boolean;
    blobURL: string;
    worker: Worker;
    events: PeopleDraw[][] = [];

    constructor() {
        this.wsClient = new WsClients();
        this.subscriptionStopped = true;

        const blob = new Blob([
            `var i = 0;

            function timedCount() {
            i = i + 1;
            postMessage(i);
            setTimeout("timedCount()", 2900);
            }

            timedCount();`,
        ]);
        this.blobURL = window.URL.createObjectURL(blob);
    }

    public SetMainViewSubscription(viz: Visualization) {
        this.viz = viz;
        this.subscriptionStopped = false;

        this.wsClient.clients.forEach(
            (client, idx) =>
                (client.onmessage = (message) => this.subscribeFunction.bind(this)(message, idx))
        );
    }

    private subscribeFunction(message: MessageEvent, idx: number) {
        const data = JSON.parse(message.data);

        this.events[idx] = data.map((person) => PeopleLiveDataToPeopleDraw(person));

        this.viz.people.UpdateMeshes(this.events.flat());
    }

    public StopMainViewSubscription() {
        this.wsClient.clients.forEach((client) => (client.onmessage = null));
        this.viz.people.HidePeople();
        this.subscriptionStopped = true;
    }

    public FocusedOut() {
        this.worker = new Worker(this.blobURL);
        this.worker.onmessage = this.RefreshConnection.bind(this);
    }

    public FocusedIn() {
        this.worker?.terminate();
    }

    public RefreshConnection() {
        if (!this.subscriptionStopped) {
            this.wsClient.Close();
            this.wsClient.Open();
            this.SetMainViewSubscription(this.viz);
        }
    }
}
