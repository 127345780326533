import { AssetData, PeopleDraw } from '_types';
import { MetadataElevator, MetadataEscalator } from '_types/features';
import {
    ElevatorListItem,
    EscalatorListItem,
    PeopleListItem,
    PeopleLiveItem,
} from '_types/queries';
import * as THREE from 'three';

const elevatorsColorMap = {
    'normal': 0x1ed273,
    'sync': 0x1ed273,
    'ATS': 0x1ed273,
    'PRL_L': 0x1ed273,
    'PAD': 0x1ed273,
    'PRC': 0x1ed273,
    'RES_E': 0x1ed273,
    'inspection': 0xffa023,
    'fireman': 0xffa023,
    'EPD': 0xffa023,
    'DOP_CS': 0xffa023,
    'OSS': 0xffa023,
};
const escalatorsColorMap = {
    'no operation mode': 0x1ed273,
    'automatic mode': 0x1ed273,
    'continuous mode': 0x1ed273,
    'two direction mode': 0x1ed273,
    'energy saving mode': 0x19ffd1,
    'Start mode': 0x1ed273,
    'Inspection mode': 0xffa023,
    'Construct mode': 0xffa023,
    'NULL': 0xffa023,
};

export const densityAreaColorMap = {
    'A': 0x0071b9,
    'B': 0x00ffff,
    'C': 0x58ab27,
    'D': 0xfcc400,
    'E': 0xf6a800,
    'F': 0xbe2126,
};

export function PeopleLiveDataToPeopleDraw(person: PeopleLiveItem): PeopleDraw {
    const position = {
        x: +person.x,
        y: +person.y,
        z: +person.z,
    };
    const id = person.ObjectID;

    return { position, id };
}

export function PeopleListItemToPeopleDraw(person: PeopleListItem): PeopleDraw {
    const position = JSON.parse(person.position);
    const id = '' + person.ObjectID;

    return { position, id };
}

export const getElevatorNameFromId = (id: number, elevators: MetadataElevator[]): string => {
    if (!elevators) return '';

    let result = '';
    elevators.forEach((elevator) => {
        if (elevator.id === id) result = elevator.name;
    });

    return result;
};

export function ElevatorListItemToAssetDataArray(
    elevatorAsset: MetadataElevator | undefined,
    elevatorEvent: ElevatorListItem
) {
    if (!elevatorAsset) {
        console.error('Elevator ', elevatorEvent.EquipmentNumber, ' not found');
        return;
    }

    const assetDataArray: AssetData[] = [];

    const position = JSON.parse(elevatorEvent.position);

    const color = new THREE.Color(elevatorsColorMap[elevatorEvent.mode] ?? 0x4f9fdd);

    assetDataArray.push({
        name: elevatorAsset.name,
        position: position,
        color: color,
        opacity: 0.6,
    });

    if (elevatorAsset.shaft) assetDataArray.push({ name: elevatorAsset.shaft, color: color });

    return assetDataArray;
}

export function EscalatorListItemToAssetData(
    escalatorAsset: MetadataEscalator | undefined,
    escalatorEvent: EscalatorListItem
) {
    if (!escalatorAsset) {
        console.error('Escalator ', escalatorEvent.EquipmentNumber, ' not found');
        return;
    }

    const color = new THREE.Color(escalatorsColorMap[escalatorEvent.mode] ?? 0x4f9fdd);

    return {
        name: escalatorAsset.name,
        color: color,
        opacity: 0.6,
    };
}

export function getCloserSmallerNumber(numList: number[], givenNum: number): number {
    let smallerNum = Number.NEGATIVE_INFINITY;

    for (const num of numList) {
        if (num <= givenNum && num > smallerNum) {
            smallerNum = num;
        }
    }

    return smallerNum;
}
