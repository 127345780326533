import React from 'react';
import { ReactComponent as AlertTriangolFilledIcon } from 'assets/icons/alert-triangle-filled.svg';
import { ReactComponent as WarningCircleFilledIcon } from 'assets/icons/warning-circle-filled.svg';
import { ReactComponent as CheckCircleFilledIcon } from 'assets/icons/check-circle-filled.svg';
import { ReactComponent as CloseCrossIcon } from 'assets/icons/close-cross.svg';
import { ReactComponent as TooltipArrow } from 'assets/icons/Arrow.svg';
import './alertTooltip.scss';
import { Button } from 'components/UIComponents/Button/Button';

export const AlertsTooltipIcon = (severity: 1 | 2 | 3) => {
    return severity === 3 ? (
        <AlertTriangolFilledIcon />
    ) : severity === 2 ? (
        <WarningCircleFilledIcon />
    ) : (
        <CheckCircleFilledIcon />
    );
};

export const AlertsTooltipButton = () => {
    return <Button className="secondary">Close</Button>;
};

export const AlertsTooltipArrow = (color: string) => {
    return <TooltipArrow fill={color} />;
};

export const AlertsTooltipCloseIcon = () => {
    return <CloseCrossIcon />;
};
