import { gql } from '@apollo/client';

export const GETBRIEFINGS = gql`
    query MyQuery {
        liveGenAiPrompt {
            items {
                response
                time
            }
        }
    }
`;

export const GETSERVICEORDERVISITINFO = gql`
    query MyQuery {
        getServiceOrderVisitInfo {
            equipmentNumber
            sch_maint_date
            serviceOrderNumber
            status
            type
        }
    }
`;

export const GETESCALATORDOWNALERTS = gql`
    query MyQuery {
        listEscalatorDownAlerts {
            description
            eq
            event_type
            site
            timestamp
        }
    }
`;
