import { useQuery } from '@apollo/client';
import { GETMETROZONESDATA } from '_queries';
import { MetroZoneDataType } from '_types';
import HorizontalBarGraph from 'components/Graphs/HorizontalBarGraph';
import React from 'react';

const MetroToEast: React.FC = () => {
    const query = useQuery<MetroZoneDataType>(GETMETROZONESDATA);
    /* React.useEffect(() => {
        query.startPolling(2000);

        return () => query.stopPolling();
    }, []); */

    const queriedData = query.data?.getMetroZoneData;
    const [data, setData] = React.useState<(number | null | string)[][]>([]);

    React.useEffect(() => {
        if (!queriedData) return;

        const westMetroData = queriedData.Emetro;

        setData(
            [
                [
                    'Car A',
                    westMetroData.Emetro_zone_A1,
                    westMetroData.Emetro_zone_A2,
                    westMetroData.Emetro_zone_A3,
                ],
                [
                    'Car B',
                    westMetroData.Emetro_zone_B1,
                    westMetroData.Emetro_zone_B2,
                    westMetroData.Emetro_zone_B3,
                ],
                [
                    'Car C',
                    westMetroData.Emetro_zone_C1,
                    westMetroData.Emetro_zone_C2,
                    westMetroData.Emetro_zone_C3,
                ],
                [
                    'Car D',
                    westMetroData.Emetro_zone_D1,
                    westMetroData.Emetro_zone_D2,
                    westMetroData.Emetro_zone_D3,
                ],
            ].reverse()
        );
    }, [queriedData]);

    return (
        <HorizontalBarGraph
            title="Metro to west"
            data={data}
            names={['Door 1', 'Door 2', 'Door 3']}
            color={'#e06325'}
            seriesOption={{
                label: { show: false },
            }}
            yAxisOption={{
                position: 'left',
            }}
            tooltipOption={{
                trigger: 'item',
                formatter: (params) =>
                    params.dimensionNames[params.seriesIndex + 1] +
                    '</br>' +
                    params.data[params.seriesIndex + 1],
            }}
            styleProps={{ height: '450px' }}
            legendOption={{
                show: false,
            }}
            loading={query.loading}
            error={!!query.error}
        />
    );
};

export default MetroToEast;
