import React from 'react';
import './segmentControl.scss';
import { capitalize } from 'components/util';

type Props = {
    options: string[];
    eOnChange: (value: string) => void;
    alerts?: string[];
    active?: string;
};

const SegmentControl: React.FC<Props> = (props) => {
    const { options, eOnChange, active } = props;
    return (
        <div className="segment-control">
            {options.map((option) => (
                <SegmentOption
                    active={active === option}
                    onClick={() => eOnChange(option)}
                    key={option}>
                    {option}
                </SegmentOption>
            ))}
        </div>
    );
};

export default SegmentControl;

type OptionProps = React.PropsWithChildren<{ active: boolean; onClick: () => void }>;

const SegmentOption: React.FC<OptionProps> = ({ active, onClick, children }) => {
    return (
        <div className={`segment-option ${active ? 'active' : ''}`} onClick={onClick}>
            {children && typeof children === 'string' && capitalize(children)}
        </div>
    );
};
