import { useQuery } from '@apollo/client';
import { GETPEOPLECOUTDATADAILYBYHOUR } from '_queries';
import { PeopleCountDaily } from '_types';
import BarGraphWithAverage from 'components/Graphs/BarGraphWithAverage';
import moment from 'moment';
import React from 'react';

const PeopleFlowDailyChart: React.FC = () => {
    const query = useQuery<PeopleCountDaily>(GETPEOPLECOUTDATADAILYBYHOUR);
    /* React.useEffect(() => {
        query.startPolling(2000);

        return () => query.stopPolling();
    }, []); */

    const queriedData = query.data?.getPeopleCountDataRDS;
    const [data, setData] = React.useState<(number | null)[][]>([]);

    React.useEffect(() => {
        if (!queriedData) return;

        setData(parseData(queriedData));
    }, [queriedData]);

    return (
        <BarGraphWithAverage
            title="People Flow for today"
            data={data}
            names={['Incoming', 'Outgoing', 'Average']}
            xAxisOption={{
                axisLabel: {
                    formatter: (n) =>
                        moment(n * 1000).format(`HH`) +
                        '-' +
                        moment(n * 1000)
                            .add(1, 'hour')
                            .format(`HH`) +
                        ' ',
                    rotate: 45,
                },
                axisTick: {
                    alignWithLabel: true,
                },
            }}
            yAxisOption={{
                min: 0,
            }}
            seriesOption={{
                label: { show: false },
                stack: 'Stack',
            }}
            tooltipOption={{
                trigger: 'item',
                formatter: (params) =>
                    params.seriesName + '</br>' + params.data[params.seriesIndex + 1],
            }}
            loading={query.loading}
            error={!!query.error}
        />
    );
};

export default PeopleFlowDailyChart;

type ParsedDataType = Record<string, { incoming: number; outgoing: number }>;

const parseData = (dataNotFormatted: { avg_last_3_days: string; today_data: string }) => {
    const todayData: ParsedDataType = JSON.parse(
        dataNotFormatted.today_data
            .replace(/=/g, ':')
            .replace(/(\d+)=/g, '"$1":')
            .replace(/(\w+)(?=:)/g, '"$1"')
    );
    const averageData: ParsedDataType = JSON.parse(
        dataNotFormatted.avg_last_3_days
            .replace(/=/g, ':')
            .replace(/(\d+)=/g, '"$1":')
            .replace(/(\w+)(?=:)/g, '"$1"')
    );

    const returnData: (number | null)[][] = [];
    const averageDataKeys = Object.keys(averageData);
    for (let idx = 0; idx < averageDataKeys.length / 2; idx++) {
        const key = averageDataKeys[idx * 2];
        const keyPlus1 = idx * 2 + 1 >= averageDataKeys.length ? '' : averageDataKeys[idx * 2 + 1];
        returnData.push([
            moment()
                .hour(+key)
                .startOf('hour')
                .valueOf() / 1000,
            (todayData[key]?.incoming ?? 0) + (todayData[keyPlus1]?.incoming ?? 0) || null,
            (todayData[key]?.outgoing ?? 0) + (todayData[keyPlus1]?.outgoing ?? 0) || null,
            averageData[key].incoming +
                averageData[key].outgoing +
                (averageData[keyPlus1]?.incoming ?? 0) +
                (averageData[keyPlus1]?.outgoing ?? 0),
        ]);
    }

    return returnData;
};
