import React from 'react';
import { toast, ToastContainerProps } from 'react-toastify';
import { Path } from 'wouter';
import { store } from 'app/store';
import { setCurrentCameraView, setMainView } from 'features/StationView/StationViewSlice';
import { apolloClient } from 'app/clients/apolloClient';
import { GETVIDEOMONITORINGALERTS, SUBSCRIBEALERTS } from '_queries';
import { AlertToaster } from 'components/AlertToaster/AlertToaster';
import { AlertsQuery } from '_types/queries';
import { setAlertPanel, setAlerts } from 'features/StationView/AlertSlice';
import { CameraView } from '_types';

type setLocationProps = (
    to: Path,
    options?: {
        replace?: boolean;
    }
) => void;

type AlertViewOptions = {
    view: CameraView;
};

class AlertManager {
    private static instance: AlertManager;
    setLocation: setLocationProps;

    constructor(setLocation: setLocationProps) {
        AlertManager.instance = this;
        this.setLocation = setLocation;

        apolloClient.subscribe({ query: SUBSCRIBEALERTS }).subscribe({
            next: ({ data: { onUpdateVideoMonitoringAlerts: alert } }) => {
                AlertManager.getInstance().ThrowPopUpAlert(alert);
            },
            error: (e) => console.error(e),
        });

        this.ListAlerts();

        // setTimeout(() => AlertManager.getInstance().ThrowPopUpAlert(mockAlert), 1000);
    }

    public static getInstance(): AlertManager {
        return AlertManager.instance;
    }

    private ListAlerts() {
        apolloClient
            .watchQuery<{ getVideoMonitoringAlerts: AlertsQuery[] }>({
                query: GETVIDEOMONITORINGALERTS,
                pollInterval: 500,
            })
            .subscribe({
                next: ({ data }) => {
                    store.dispatch(setAlerts(data.getVideoMonitoringAlerts));
                },
                error: (e) => console.error(e),
            });
    }

    public ThrowPopUpAlert(alert: MockAlertType, ToastProps: ToastContainerProps = {}) {
        const level = 4;
        toast(
            ({ closeToast }) => {
                return (
                    <AlertToaster
                        severity={1}
                        title={alert.genai_message}
                        closeToast={closeToast}
                        alertOptions={{
                            timestamp: alert.time_stamp,
                            site: alert.site_id,
                        }}
                        onInvestigate={
                            alert.site_id
                                ? () => this.SetViewToAlertPanel(alert.site_id)
                                : undefined
                        }></AlertToaster>
                );
            },
            {
                autoClose: AlertWarningLevelMap[level].expireTimeMs as number | false | undefined,
                className: 'alert-container',
                bodyClassName: 'alert-body',
                progressClassName: 'alert-progress-bar',
                progressStyle: { background: '#fff1ec' },
                icon: null,
                ...ToastProps,
            }
        );

        if (alert.panelPosition) {
            store.dispatch(
                setAlertPanel({
                    position: {
                        x: +alert.panelPosition.x,
                        y: +alert.panelPosition.y,
                        z: +alert.panelPosition.z,
                    },
                    content: alert.genai_message,
                    severity: 3,
                    id: alert.alarm_id,
                })
            );
        }
    }

    public ActionsOnAlert() {
        console.log('To implement...');
    }

    public SetViewToAlertPanel(site_id: string, options?: AlertViewOptions, live?: boolean) {
        this.setLocation(`/stations/${site_id.toLowerCase()}`);

        if (live) {
            store.dispatch(setMainView('live'));
            return;
        }

        if (!options) {
            store.dispatch(setMainView('virtual operator'));
            return;
        }

        store.dispatch(setMainView('live'));
        store.dispatch(setCurrentCameraView(options.view));
    }
}

export default AlertManager;

export const AlertWarningLevelMap = {
    1: { color: '#5df542', expireTimeMs: 5000 },
    2: { color: '#e1f719', expireTimeMs: 5000 },
    3: { color: '#f7bc19', expireTimeMs: 10000 },
    4: { color: '#ff5f28', expireTimeMs: false },
};

type MockAlertType = AlertsQuery & {
    panelPosition: { x: string; y: string; z: string };
};

export const mockAlert: MockAlertType = {
    time_stamp: '1724739663',
    version: '1',
    message: 'alarm',
    site_id: 'Rautatientori',
    equipment_id: 'esc_1',
    genai_message: 'Person falling down the escalator',
    camera_id: 'Vsm232',
    alarm_type: 'out of handrail',
    alarm_status: 'incident start',
    alarm_id: '101719884889',
    read_status: 0,
    panelPosition: { x: '162', y: '-5.2', z: '-159' },
};
