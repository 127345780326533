import { Viewport } from './viewport';
import * as THREE from 'three';
import { TypeOfFeatures, TrainStore, Directions } from '_types';
import { DirectionType } from '_types/trainDataManager';

export class TrainAssets {
    vpt: Viewport;
    scene: THREE.Group;
    viewPoints: Map<string, Array<string>>;
    trains: TrainStore;
    steps = 200;
    defaultOpacity = 0.4;
    movementPositions: { [key: string]: Directions } = {
        from_east: {
            directionType: 'to_west',
            start: { position: new THREE.Vector3(110, 3.2, -175), opacity: 0 },
            end: {
                position: new THREE.Vector3(-22, 3.2, -168.5),
                opacity: this.defaultOpacity,
            },
        },
        to_west: {
            directionType: 'to_west',
            start: {
                position: new THREE.Vector3(-22, 3.2, -168.5),
                opacity: this.defaultOpacity,
            },
            end: { position: new THREE.Vector3(-150, 3.2, -164), opacity: 0 },
        },
        from_west: {
            directionType: 'to_east',
            start: { position: new THREE.Vector3(-120, 3.2, -128), opacity: 0 },
            end: { position: new THREE.Vector3(-15, 3.2, -133), opacity: this.defaultOpacity },
        },
        to_east: {
            directionType: 'to_east',
            start: { position: new THREE.Vector3(-15, 3.2, -133), opacity: this.defaultOpacity },
            end: { position: new THREE.Vector3(110, 3.2, -139), opacity: 0 },
        },
    };
    trainsAreVisible: boolean;
    initialOpacity = {
        to_east: 0,
        to_west: 0,
    };
    animationInProgress = {
        to_west: false,
        to_east: false,
    };
    lastTrains = {
        to_east: '',
        to_west: '',
    };
    trackEmpty = {
        to_east: true,
        to_west: true,
    };

    constructor() {
        this.trains = {};
        this.trainsAreVisible = true;
    }

    public SetTrains(train: THREE.Mesh<THREE.BufferGeometry, THREE.MeshPhysicalMaterial>) {
        const trainName = train.name.includes('to_west') ? 'to_west' : 'to_east';

        if (trainName === 'to_west') train.position.set(-22, 3.2, -168.5);
        else train.position.set(-15, 3.2, -133);

        train.material.transparent = true;
        train.material.opacity = this.initialOpacity[trainName];
        train.material.color = new THREE.Color(0xf04f05);

        this.trains[trainName] = train;
    }

    public TrainFromEast(trainId: string) {
        if (this.animationInProgress.to_west || trainId === this.lastTrains.to_west) return;
        else {
            this.animationInProgress.to_west = true;
            this.lastTrains.to_west = trainId;
            this.trackEmpty.to_west = false;
            this.AnimateTrainsInDirection(this.movementPositions.from_east);
        }
    }
    public TrainFromWest(trainId: string) {
        if (this.animationInProgress.to_east || trainId === this.lastTrains.to_east) return;
        else {
            this.animationInProgress.to_east = true;
            this.lastTrains.to_east = trainId;
            this.trackEmpty.to_east = false;
            this.AnimateTrainsInDirection(this.movementPositions.from_west);
        }
    }
    public TrainToEast() {
        if (this.animationInProgress.to_east || this.trackEmpty.to_east) return;
        else {
            this.animationInProgress.to_east = true;
            this.trackEmpty.to_east = true;
            this.AnimateTrainsInDirection(this.movementPositions.to_east);
        }
    }
    public TrainToWest() {
        if (this.animationInProgress.to_west || this.trackEmpty.to_west) return;
        else {
            this.animationInProgress.to_west = true;
            this.trackEmpty.to_west = true;
            this.AnimateTrainsInDirection(this.movementPositions.to_west);
        }
    }

    private AnimateTrainsInDirection(direction: Directions) {
        if (!this.trainsAreVisible) return;

        const train = this.trains[direction.directionType];

        if (!train) {
            setTimeout(() => this.AnimateTrainsInDirection(direction), 100);
            return;
        }

        let count = 0;

        const START = direction.start;
        const END = direction.end;
        const positionDelta = END.position.clone().sub(START.position);
        const opacityDelta = END.opacity - START.opacity;
        const steps = this.steps;

        train.visible = true;

        const blob = new Blob([
            `var i = 0;

            function timedCount() {
            i = i + 1;
            postMessage(i);
            setTimeout("timedCount()", 16);
            }

            timedCount();`,
        ]);

        const blobURL = window.URL.createObjectURL(blob);

        const worker = new Worker(blobURL);

        const onMessageFunction = () => {
            if (count >= steps) {
                this.animationInProgress[direction.directionType] = false;
                worker.terminate();
                return;
            }

            const increment = count / (steps - 1);

            train.position.copy(
                START.position.clone().add(positionDelta.clone().multiplyScalar(increment))
            );
            train.material.opacity = START.opacity + opacityDelta * increment;

            count++;

            worker.postMessage('start timer');
        };

        worker.onmessage = onMessageFunction.bind(this);
        worker.postMessage('start timer');
    }

    public UpdateTrainStatus(directionName: DirectionType, percentage: number) {
        const direction: Directions = this.movementPositions[directionName];
        const train = this.trains[direction.directionType];

        if (!train) return;

        const START = direction.start;
        const END = direction.end;
        const positionDelta = END.position.clone().sub(START.position);
        const opacityDelta = END.opacity - START.opacity;

        train.position.copy(
            START.position.clone().add(positionDelta.clone().multiplyScalar(percentage))
        );
        train.material.opacity = START.opacity + opacityDelta * percentage;
    }

    public SetTrainsAreVisible(areVisible: boolean) {
        this.trainsAreVisible = areVisible;

        if (!areVisible) Object.values(this.trains).forEach((obj) => (obj.visible = false));
        else Object.values(this.trains).forEach((obj) => (obj.visible = true));
    }

    public SetInitialVisible(directionType: string, trainId: string) {
        this.initialOpacity[directionType] = this.defaultOpacity;
        this.lastTrains[directionType] = trainId;
        this.trackEmpty[directionType] = false;
    }
}
