import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartsCoreOption } from 'echarts';
import { isAverage } from './graphUtils';
import Spinner from 'components/LoadingPage/Spinner';

interface Props {
    title: string;
    data: (number | null | string)[][]; // [time, ...values, average]
    names: string[]; // if average is present it must be named 'average' (case insensitive)
    color?: string[];
    xAxisOption?: Record<string, unknown> & { axisLabel?: Record<string, unknown> };
    yAxisOption?: Record<string, unknown> & { axisLabel?: Record<string, unknown> };
    seriesOption?: Record<string, unknown>;
    tooltipOption?: Record<string, unknown>;
    graphOption?: Record<string, unknown>;
    loading?: boolean;
    error?: boolean;
}

const BarGraphWithAverage: React.FC<Props> = ({
    title,
    data,
    names,
    color = ['#7296F9', '#4373F7', '#1450F5'],
    xAxisOption,
    yAxisOption,
    seriesOption,
    tooltipOption,
    graphOption,
    loading,
    error,
}) => {
    if (!data || data[0]?.length === 0 || loading)
        return (
            <div className="chart-container">
                <div className="chart-title">
                    <p>{title}</p>
                </div>
                <div className="spinner-container">
                    <Spinner />
                </div>
            </div>
        );

    if (error)
        return (
            <div className="chart-container">
                <div className="chart-title">
                    <p>{title}</p>
                </div>
                <div className="error-container spinner-container">Ops, something went wrong</div>
            </div>
        );

    const dimensions = ['timestamp', ...names];

    const series = dimensions.slice(1).map((name) => ({
        encode: {
            x: 'timestamp',
            y: name,
        },
        color: isAverage(name) ? '#D0D0D0' : undefined,
        type: isAverage(name) ? 'line' : 'bar',
        name,
        smooth: 0.3,
        connectNulls: true,
        label: {
            show: !isAverage(name),
            position: 'inside', // Show labels inside the bars
            color: '#fff', // Label text color
            fontSize: 10,
        },
        ...seriesOption,
    }));

    const textStyle = { color: '#fff', fontSize: 10 };
    const option: EChartsCoreOption = {
        color,
        legend: {
            data: dimensions.slice(1),
            top: 'top',
            left: 'right',
            textStyle: { ...textStyle, fontSize: 14 },
        },
        xAxis: {
            type: 'category',
            splitLine: {
                show: false,
            },
            ...xAxisOption,
            axisLabel: {
                ...textStyle,
                ...xAxisOption?.axisLabel,
            },
        },
        yAxis: {
            ...yAxisOption,
            axisLabel: {
                ...textStyle,
                ...yAxisOption?.axisLabel,
            },
        },
        dataset: {
            source: data,
            dimensions: dimensions,
        },
        series: series,
        tooltip: {
            trigger: false,
            ...tooltipOption,
        },
        ...graphOption,
    };
    return (
        <div className="chart-container">
            <div className="chart-title">
                <p>{title}</p>
            </div>
            <ReactEcharts option={option} />
        </div>
    );
};
export default BarGraphWithAverage;
