import React, { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import LiveView from './LiveView';

const StationTwinLive: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    return (
        <>
            {/* <StatsSidebar stationName={stationName} /> */}
            <LiveView />
        </>
    );
};

export default StationTwinLive;
