import React from 'react';
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs';
import './playbackSlider.scss';

interface PlaybackSliderProps {
    start: Date | number;
    end: Date | number;
    reached: Date | number;
    onInputChange: (input: number) => void;
    labels?: {
        start: string;
        end: string;
    };
    topper?: React.ReactNode;
    inputInterval?: number;
}

const PlaybackSlider: React.FC<PlaybackSliderProps> = (props) => {
    const { start, end, reached, onInputChange, labels, inputInterval = 100, topper } = props;
    const [startMs, endMs, reachedMs] = [
        typeof start === 'number' ? start : start.getTime(),
        typeof end === 'number' ? end : end.getTime(),
        typeof reached === 'number' ? reached : reached.getTime(),
    ];

    const [inputValue, setInputValue] = React.useState<number>(startMs);
    const [isPlayButtonStopped, setIsPlayButtonStopped] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!isPlayButtonStopped) {
            const playButtonInterval = setInterval(() => {
                setInputValue((input) => input + inputInterval);
            }, inputInterval);

            return () => clearInterval(playButtonInterval);
        }
    }, [isPlayButtonStopped]);

    React.useEffect(() => {
        onInputChange(inputValue);
    }, [inputValue]);

    return (
        <div className="timeline">
            <button
                onClick={() => setIsPlayButtonStopped(!isPlayButtonStopped)}
                className="play-stop-button">
                {isPlayButtonStopped ? (
                    <BsFillPlayFill size="30px" />
                ) : (
                    <BsFillPauseFill size="30px" />
                )}
            </button>
            <div className="data">
                {labels && (
                    <div className="labels">
                        <span>{labels.start}</span>
                        <span>{labels.end}</span>
                    </div>
                )}
                {topper}
                <div className="axis">
                    <input
                        type="range"
                        min={startMs}
                        max={reachedMs}
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(+e.target.value);
                        }}
                        style={{
                            width: `${((reachedMs - startMs) * 100) / (endMs - startMs)}%`,
                            background:
                                'linear-gradient(to right, var(--kone-blue) 0%, var(--kone-blue) ' +
                                ((inputValue - startMs) * 100) / (reachedMs - startMs) +
                                '%, white ' +
                                ((inputValue - startMs) * 100) / (endMs - startMs) +
                                '%, white 100%)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlaybackSlider;
