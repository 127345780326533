import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

const url = process.env.REACT_APP_API_URL || '';
const region = 'eu-west-1';

const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
};

// Create the AppSync GraphQL API link with authentication.

const httpLink = new HttpLink({
    uri: url,
});

const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

/* // ADD A SECOND GRAPHQL ENDPOINT

const secondLink = new HttpLink({
    uri: 'URL',
});

//AUTH FOR 2ND ENDPOINT
const secondAuthLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            'Content-type': 'application/graphql',
            'x-api-key': 'KEY',
        },
    };
});

// SPLIT LINK WITH LOGIC FOR DISCRIMINATING
const splitLink = split(
    (operation) => {
        const defintion = getMainDefinition(operation.query);
        return defintion.kind === 'OperationDefinition' && defintion.operation === 'subscription';
    }, // context is an object to be passe dby query, use a key-value in it to check againt
    link,
    ApolloLink.from([createAuthLink({ url, region, auth }), httpLink])
); */

export const apolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
});
