import { gql } from '@apollo/client';

export const SUBSCRIBEALERTS = gql`
    subscription MySubscription {
        onUpdateVideoMonitoringAlerts {
            alarm_status
            alarm_id
            alarm_type
            camera_id
            equipment_id
            genai_message
            message
            site_id
            time_stamp
            version
        }
    }
`;

export const GETVIDEOMONITORINGALERTS = gql`
    query MyQuery($site_id: String) {
        getVideoMonitoringAlerts(site_id: $site_id) {
            alarm_id
            alarm_status
            alarm_type
            camera_id
            equipment_id
            genai_message
            message
            read_status
            site_id
            time_stamp
            timestamp
            version
        }
    }
`;

export const SETVIDEOMONITORINGALERTSREAD = gql`
    mutation MyMutation($time_stamp: String!, $site_id: String!) {
        updateReadStatusVideoMonitoringAlerts(
            time_stamp: $time_stamp
            read_status: 1
            site_id: $site_id
        ) {
            read_status
        }
    }
`;
