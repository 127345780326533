import React from 'react';
import './App.scss';
import { Amplify, Auth } from 'aws-amplify';
import { Route, Redirect, useLocation } from 'wouter';
import MetroLineOverview from 'components/MetroLineOverview/MetroLineOverview';
import ProtectedRoute from 'routes/protected/ProtectedRoute';
import LoginRoute from 'routes/login/LoginRoute';
import MainSidebar from 'components/MainSidebar';
import { useAppDispatch } from 'app/hooks';
import { setLoggedIn, setUser } from 'features/auth/AuthSlice';
import { StationTwinMainView } from 'components/StationTwin/StationTwinMainView';
import { TopNavbar } from 'components/TopNavbar/TopNavbar';
import NewSimulationView from 'components/Simulations/NewSimulation/NewSimulation';
import SimulationStationList from 'components/Simulations/SimulationStationList/SimulationStationList';
import SimulationsList from 'components/Simulations/SimulationsList/SimulationsList';
import SimulationMainView from 'components/StationTwinSimulation/SimulationMainView';
import { AlertToasterContainer } from 'components/AlertToaster/AlertToaster';
import NotificationCenter from 'components/NotificationCenter/NotificationCenter';
import AlertManager from 'dataManagers/alertsManager/AlertManager';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WE_CLIENT_ID,
        authenticationFlowType: process.env.REACT_APP_COGNITO_POOL_AUTH_FLOW_TYPE,
    },
});

const MainSidebarWrapper: React.FC<{
    children: JSX.Element;
    domain: string;
}> = (props) => {
    return (
        <>
            <TopNavbar />
            <div id="main-container">
                <MainSidebar domain={props.domain} />
                <div className="main-view-container">{props.children}</div>
            </div>
        </>
    );
};

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const [, setLocation] = useLocation();

    //Initializing useEffect
    React.useEffect(() => {
        new AlertManager(setLocation);
    }, []);

    Auth.currentAuthenticatedUser()
        .then((user) => {
            dispatch(setUser(user));
            dispatch(setLoggedIn(true));
        })
        .catch(() => {
            dispatch(setLoggedIn(false));
        });

    return (
        <div className="App">
            <AlertToasterContainer />
            <Route path="/">
                <Redirect to="/login" />
            </Route>
            <Route path="/login">
                <LoginRoute />
            </Route>
            <ProtectedRoute path="/overview">
                <MainSidebarWrapper domain="overview">
                    <MetroLineOverview />
                </MainSidebarWrapper>
            </ProtectedRoute>
            <ProtectedRoute path="/stations/:stationName">
                <MainSidebarWrapper domain="stations">
                    <StationTwinMainView />
                </MainSidebarWrapper>
            </ProtectedRoute>
            <ProtectedRoute path="/simulations">
                <MainSidebarWrapper domain="simulator">
                    <SimulationStationList />
                </MainSidebarWrapper>
            </ProtectedRoute>
            <ProtectedRoute path="/simulations/:stationName">
                <MainSidebarWrapper domain="simulator">
                    <SimulationsList />
                </MainSidebarWrapper>
            </ProtectedRoute>
            <ProtectedRoute path="/simulations/:stationName/new_simulation">
                <MainSidebarWrapper domain="simulator">
                    <NewSimulationView />
                </MainSidebarWrapper>
            </ProtectedRoute>
            <ProtectedRoute path="/simulations/:stationName/simulator/:simulationId">
                <MainSidebarWrapper domain="simulator">
                    <SimulationMainView />
                </MainSidebarWrapper>
            </ProtectedRoute>
            <ProtectedRoute path="/notification-center">
                <MainSidebarWrapper domain="notifications">
                    <NotificationCenter />
                </MainSidebarWrapper>
            </ProtectedRoute>
        </div>
    );
};

export default App;
