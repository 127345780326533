export function asciiFromString(string: string): number {
    let result = 0;
    for (let i = 0; i < string.length; i++) {
        result += string.charCodeAt(i);
    }
    return result;
}

export const doubleDigit = (number: number) => {
    return ('00' + number).slice(-2);
};

export function TimeFormatterFromSeconds(secondsTimestamp: number, seconds?: boolean) {
    const hours = Math.floor(secondsTimestamp / 3600);
    const mins = Math.floor((secondsTimestamp % 3600) / 60);
    const secs = Math.floor(secondsTimestamp % 60);

    return (
        (hours !== 0 ? doubleDigit(hours) + ':' : '') +
        doubleDigit(mins) +
        (seconds ? ':' + doubleDigit(secs) : '')
    );
}

// method to get value in milliseconds from string in the formats:
// hh:mm:ss or mm:ss
export function GetMillisecondsValueFromString(
    string: string,
    format: 'hh:mm:ss' | 'mm:ss' | 'hh:mm'
) {
    const split = string.split(':');
    if (format === 'hh:mm:ss') {
        return (+split[0] * 3600 + +split[1] * 60 + +split[2]) * 1000;
    } else if (format === 'mm:ss') {
        return (+split[0] * 60 + +split[1]) * 1000;
    } else {
        return (+split[0] * 3600 + +split[1] * 60) * 1000;
    }
}

export function capitalize(string?: string) {
    if (!string) return '';
    return string[0].toUpperCase() + string.slice(1);
}
