import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch } from 'app/hooks';
import KoneBrandMark from 'components/KoneBrandMark';
import { loginThunk, selectAuth, selectLoggedIn, StateStatus } from 'features/auth/AuthSlice';
import { useSelector } from 'react-redux';
import { Redirect } from 'wouter';

import './loginRoute.scss';

const LoginContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const authState = useSelector(selectAuth);

    const handleSubmitClick = useCallback(
        (e) => {
            e.preventDefault;
            const params = {
                username,
                password,
            };
            dispatch(loginThunk(params));
        },
        [username, password]
    );

    const handleUsernameChange = useCallback((e) => {
        setUsername(e.target.value);
    }, []);

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value);
    }, []);

    const buttonClass = useMemo(() => {
        return authState.status === StateStatus.PENDING ? 'pending' : '';
    }, [authState]);

    const forgotPasswordLink = process.env.REACT_APP_FORGOT_PASSWORD_URL || '';

    return (
        <div id="login">
            <div id="intro">
                <KoneBrandMark />
                <div id="contentWrapper">
                    <h1>Station Twin</h1>
                    <p>
                        Smart People Flow Management in Transit Stations.
                        <br></br>
                    </p>
                </div>
            </div>
            <div id="loginWrapper">
                <div id="loginForm">
                    <div id="error-text">
                        {authState.status == 'failed' ? 'Invalid username or password.' : ''}
                    </div>
                    <input
                        type="text"
                        placeholder="User Name"
                        value={username}
                        onChange={handleUsernameChange}
                        className={authState.status == 'failed' ? 'error' : ''}
                        id="user-name-input"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Password"
                        className={authState.status == 'failed' ? 'error' : ''}
                        id="password-input"
                    />

                    <div id="actions">
                        <a
                            className="forgot-password-link"
                            href={forgotPasswordLink}
                            id="forgot-password-link">
                            Forgot Password?
                        </a>
                        <button
                            className={buttonClass}
                            onClick={handleSubmitClick}
                            id="submit-credentials">
                            <span>LOG IN</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LoginRoute: React.FC = () => {
    const authenticated = useSelector(selectLoggedIn);

    return authenticated ? <Redirect to="/overview" /> : <LoginContainer />;
};

export default LoginRoute;
