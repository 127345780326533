import { Viewport } from './viewport';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { StationParams } from '_types';
import { AssetData } from '_types';
import { TrainAssets } from './viz_trainsAssets';
import { MetadataTrain } from '_types/features';
import { CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer';

export class StaticAssets {
    vpt: Viewport;
    trainAssets: TrainAssets;
    resources: Array<THREE.Object3D>;
    scene: THREE.Group;
    viewPoints: Map<string, Array<string>>;
    stationAssetsObjects: {
        [key: string]: THREE.Mesh<THREE.BufferGeometry, THREE.MeshPhysicalMaterial>;
    };
    css3DAlertPanel: CSS3DSprite;
    // TO BE REFATCORED
    defaultOpacity = 0.15;

    api: {
        SetSceneTransform: (pos: THREE.Vector3, scale: THREE.Vector3) => void;
        LoadModel: (
            url: string,
            trains?: MetadataTrain[],
            logProgress?: boolean,
            stationParams?: StationParams
        ) => Promise<void>;
    };

    assetsFeatures?: { [name: string]: AssetData };
    assetsFueaturesIsDirty: boolean;

    constructor(vpt: Viewport, environmentMapUrl: string) {
        this.vpt = vpt;
        this.trainAssets = new TrainAssets();
        this.SetEnvironmentMap(environmentMapUrl);
        this.resources = new Array<THREE.Object3D>(0);
        this.scene = new THREE.Group();
        this.scene.name = 'StaticScene';
        this.vpt.scene.add(this.scene);
        this.viewPoints = new Map<string, Array<string>>();
        this.stationAssetsObjects = {};
        this.assetsFeatures = {};
        this.assetsFueaturesIsDirty = false;

        this.api = {
            SetSceneTransform: this.SetSceneTransform,
            LoadModel: this.LoadModel,
        };

        this.vpt.updates.push(this.UpdateAssets.bind(this));
    }

    private SetEnvironmentMap = (url: string) => {
        this.vpt.loaders.hdri.load(url, (texture) => {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.vpt.scene.environment = texture;
        });
    };

    private SetSceneTransform = (pos: THREE.Vector3, scale: THREE.Vector3) => {
        const fullStation = this.scene.children[1];

        console.log(this.scene);
        if (fullStation != undefined) {
            fullStation.position.copy(pos);
            fullStation.scale.copy(scale);
            fullStation.updateMatrix();
        }
    };

    private LoadModel = async (url: string, trains?: MetadataTrain[], logProgress = false) => {
        // const stationParams = this.stationFeatures.stationParams;

        return this.vpt.loaders.gltf
            .loadAsync(url, (event: ProgressEvent<EventTarget>) => {
                if (!logProgress) {
                    return;
                }

                const progress: string = ((event.loaded / event.total) * 100).toFixed(0);
                console.log(`STATIC ASSETS: loaded ${progress}% of ${url}`);
            })
            .then((gltf: GLTF) => {
                this.scene.add(gltf.scene);
                this.scene.name = url;

                // applyStationParams(gltf.scene, stationParams);

                gltf.scene.traverse((obj: THREE.Object3D<THREE.Event>) => {
                    //Material replacement on Meshes
                    if (trains?.map((t) => t.name).includes(obj.name)) {
                        this.trainAssets.SetTrains(
                            obj as THREE.Mesh<THREE.BufferGeometry, THREE.MeshPhysicalMaterial>
                        );
                        return;
                    }
                    if (obj.type === 'Mesh') {
                        (obj as THREE.Mesh).material = this.vpt.structMaterial.clone();
                        this.stationAssetsObjects[obj.name] = obj as THREE.Mesh<
                            THREE.BufferGeometry,
                            THREE.MeshPhysicalMaterial
                        >;
                        ((obj as THREE.Mesh).material as THREE.MeshPhysicalMaterial).opacity =
                            this.defaultOpacity;
                    }

                    /* if (Object.keys(this.defaultOpacity1).includes(obj.name)) {
                        ((obj as THREE.Mesh).material as THREE.MeshPhysicalMaterial).opacity =
                            this.defaultOpacity1[obj.name];
                    } */

                    //Hide hidden objects
                    /* if (stationParams?.hiddenAssets?.includes(obj.name)) {
                        obj.visible = false;
                    } */
                });
            })
            .catch((error) => {
                console.error(error);
                // setTimeout(() => this.LoadModel(url, trains, logProgress), 5000);
            });
    };

    public SetAssetsFeatures(assetsData: AssetData[]) {
        const assetsObject = assetsData.reduce((o, asset) => ({ ...o, [asset.name]: asset }), {});
        this.assetsFeatures = { ...this.assetsFeatures, ...assetsObject };
        this.assetsFueaturesIsDirty = true;
    }

    private UpdateAssets() {
        if (!this.assetsFeatures || !this.stationAssetsObjects) return;
        if (!this.assetsFueaturesIsDirty) return;
        let dataIsClean = true;

        Object.values(this.assetsFeatures).forEach((assetFeature) => {
            const asset = this.stationAssetsObjects[assetFeature.name];

            dataIsClean = dataIsClean && !!asset;
            if (!asset) return;

            if (assetFeature.color) asset.material.color = assetFeature.color;

            if (typeof assetFeature.opacity === 'number') {
                asset.material.opacity = assetFeature.opacity;
            } else {
                asset.material.opacity = this.defaultOpacity;
            }

            if (assetFeature.position)
                asset.position.set(
                    assetFeature.position.x,
                    assetFeature.position.y,
                    assetFeature.position.z
                );
        });

        this.assetsFueaturesIsDirty = !dataIsClean;
    }

    public SetAssetsVisibility(visibleAssets?: { name: string; opacity?: number }[]) {
        const assetsArray = Object.values(this.stationAssetsObjects);
        if (!visibleAssets) {
            assetsArray.forEach((obj) => {
                obj.visible = true;
            });
            this.trainAssets.SetTrainsAreVisible(true);
        } else {
            assetsArray.forEach((obj) => (obj.visible = false));
            visibleAssets.forEach((visibleAsset) => {
                const asset = this.stationAssetsObjects[visibleAsset.name];
                if (!asset) return;
                asset.material.opacity = visibleAsset.opacity ?? this.defaultOpacity;
                asset.visible = true;
            });
        }
    }
}
