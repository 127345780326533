import React from 'react';
import { Link, useLocation } from 'wouter';

import './metroLineOverview.scss';
import { useQuery } from '@apollo/client';
import { GETJOURNEYTIME, GETTOTALCOUNTSINOUT, GETWEATHER } from '_queries';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import MetroLineOneLine from './MetroLine';
import { LiveJourneyTimeData, TrainData, TrainListItem, WeatherData } from '_types/queries';
import List from 'components/List/List';
import { stations } from 'features/stationFeatures/stationFeatures';
import { secToMins } from 'app/util';
import { TrainDataManager } from 'dataManagers/liveDatamanager/trainDataManager';

const reqSvgs = require.context('../../assets/icons/weather-icons/WeatherSymbol3', false, /\.svg$/);
const iconPaths = reqSvgs
    .keys()
    .filter((s) => s[0] === '.')
    .reduce((o, s) => {
        o[s.replace('./', '').replace('.svg', '')] = reqSvgs(s);
        return o;
    }, {});

type TrainsType = 'M1' | 'M2';

const MetroLineOverview: React.FC = () => {
    const [location, setLocation] = useLocation();

    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(setMainSidebarFull(true));
    }, []);

    const [currentDateTime, setCurrentDateTime] = React.useState(new Date());
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const [trainData, setTrainData] = React.useState<TrainData>();
    React.useEffect(() => {
        const tdm = new TrainDataManager();
        const interval = setInterval(() => {
            tdm.GetData().then(({ data }) => setTrainData(data));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const {
        data: weatherData,
        startPolling: startWeatherPolling,
        stopPolling: stopWeatherPolling,
    } = useQuery<WeatherData>(GETWEATHER);
    const {
        data: peopleCount,
        startPolling: startPeopleCount,
        stopPolling: stopPeopleCount,
    } = useQuery(GETTOTALCOUNTSINOUT);
    React.useEffect(() => {
        startWeatherPolling(60000);
        startPeopleCount(500);

        return () => {
            stopWeatherPolling();
            stopPeopleCount();
        };
    }, []);
    const totalPassengers = peopleCount?.getTotalCountsInOut?.[0]
        ? peopleCount?.getTotalCountsInOut?.[0]?.incoming_people_count +
          peopleCount?.getTotalCountsInOut?.[0]?.outgoing_people_count
        : '--';

    const stationsList = stations.reduce((o, stationName) => {
        o[stationName] = { trains: {}, weather: {} };
        if (stationName === 'Rautatientori') o[stationName].link = true;
        return o;
    }, {});

    const [metroStations, setMetroStations] = React.useState<{
        [stationName: string]: {
            link?: boolean;
            trains: {
                eStation?: TrainsType;
                eComing?: TrainsType;
                wStation?: TrainsType;
                wComing?: TrainsType;
            };
            weather: {
                src?: string;
                temperature?: string;
            };
        };
    }>(JSON.parse(JSON.stringify(stationsList)));

    React.useEffect(() => {
        setMetroStations((ms) => {
            Object.values(ms).forEach((s) => (s.trains = {}));
            return ms;
        });
    }, []);

    React.useEffect(() => {
        setMetroStations((ms) => {
            Object.values(ms).forEach((s) => (s.trains = {}));
            return ms;
        });
        if (trainData?.listMetroEvents?.items) {
            trainData.listMetroEvents.items.forEach((train: TrainListItem) => {
                setMetroStations((ms) => {
                    const params = ms[train.stop_id.replace('-', ' ')];

                    if (train.direction_id === 0) {
                        if (train.current_status === 1)
                            params.trains.eStation = train.route_id.slice(-2) as TrainsType;
                        else params.trains.eComing = train.route_id.slice(-2) as TrainsType;
                    } else {
                        if (train.current_status === 1)
                            params.trains.wStation = train.route_id.slice(-2) as TrainsType;
                        else params.trains.wComing = train.route_id.slice(-2) as TrainsType;
                    }

                    return ms;
                });
            });
        }
    }, [trainData]);

    const {
        data: jtData,
        startPolling: jtStartPolling,
        stopPolling: jtStopPolling,
    } = useQuery<LiveJourneyTimeData>(GETJOURNEYTIME);

    React.useEffect(() => {
        jtStartPolling(500);

        return jtStopPolling;
    }, [jtData]);

    return (
        <div id="metroLineOverview">
            <main>
                <div id="title">
                    <div id="metro-sign-container">
                        <div id="metro-sign">
                            <span>M</span>
                        </div>
                        <div id="text">
                            <h2>Helsinki</h2>
                            <h2>Metrolinja</h2>
                        </div>
                    </div>
                    <div id="date-time">
                        <span> {currentDateTime.toLocaleTimeString()} </span>
                        <span>{currentDateTime.toLocaleDateString('en-GB')}</span>
                    </div>
                </div>
                <div style={{ marginTop: '3rem' }}></div>
                <List
                    head={
                        <>
                            <tr>
                                <td>STATION</td>
                                <td>
                                    <span>TO EAST</span>
                                </td>
                                <td>
                                    <span>TO WEST</span>
                                </td>
                                <td>TOTAL PASSENGERS</td>
                                <td>PEOPLE ON PLATFORM</td>
                                <td colSpan={2}>JOURNEY TIME (min)</td>
                            </tr>
                            <tr>
                                <td colSpan={5}></td>
                                <td>IN</td>
                                <td>OUT</td>
                            </tr>
                        </>
                    }>
                    {Object.entries(metroStations).map(([name, params]) => {
                        const stationWeather = weatherData?.getTemperatureDataRDS?.find(
                            (weatherObj) => weatherObj.Station_real_name.replace('-', ' ') === name
                        );
                        const weatherIconId = (stationWeather?.Weather &&
                            +stationWeather.Weather) as number | undefined;
                        return (
                            <tr key={name}>
                                <td className="station-name-cell">
                                    <div
                                        className="station-name"
                                        onClick={() =>
                                            setLocation(
                                                params.link
                                                    ? `/stations/${name
                                                          .toLowerCase()
                                                          .replace(' ', '_')}`
                                                    : '#'
                                            )
                                        }>
                                        {name}
                                    </div>
                                    {stationWeather && weatherIconId && (
                                        <div className="weather-data">
                                            <img src={iconPaths[weatherIconId]}></img>
                                            <span>
                                                {Math.round(+stationWeather.Air_temperature * 10) /
                                                    10}
                                                °
                                            </span>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <MetroLineOneLine
                                        name={name.toLowerCase()}
                                        direction="east"
                                        trainComing={params.trains.eComing}
                                        trainInStation={params.trains.eStation}
                                    />
                                </td>
                                <td>
                                    <MetroLineOneLine
                                        name={name.toLowerCase()}
                                        direction="west"
                                        trainComing={params.trains.wComing}
                                        trainInStation={params.trains.wStation}
                                    />
                                </td>
                                <td className="station-health">
                                    {name === 'Rautatientori' ? totalPassengers : '--'}
                                </td>
                                <td
                                    className="station-health" /* +platformCongestion(name) > 90 ? 'alert' : '' */
                                >
                                    <div>--</div>
                                </td>
                                <td className="station-health">
                                    {name === 'Rautatientori'
                                        ? secToMins(
                                              jtData
                                                  ? Math.round(
                                                        jtData.listLiveJourneyEvents.items
                                                            .filter(
                                                                (o) => o.traffic_type === 'incoming'
                                                            )
                                                            .reduce(
                                                                (n, o) => n + o.avg_journey_time_s,
                                                                0
                                                            ) / 2
                                                    )
                                                  : undefined
                                          )
                                        : '--'}
                                </td>
                                <td className="station-health">
                                    {name === 'Rautatientori'
                                        ? secToMins(
                                              jtData
                                                  ? Math.round(
                                                        jtData.listLiveJourneyEvents.items
                                                            .filter(
                                                                (o) => o.traffic_type === 'outgoing'
                                                            )
                                                            .reduce(
                                                                (n, o) => n + o.avg_journey_time_s,
                                                                0
                                                            ) / 2
                                                    )
                                                  : undefined
                                          )
                                        : '--'}
                                </td>
                            </tr>
                        );
                    })}
                </List>
                <div id="weather-info">
                    <span>Weather data provided by</span>
                    <a
                        href="https://en.ilmatieteenlaitos.fi/open-data"
                        target="_blank"
                        rel="noreferrer noopener">
                        <img src={require('../../assets/FMI0DATA_280.png')}></img>
                    </a>
                </div>
            </main>
        </div>
    );
};

export default MetroLineOverview;
