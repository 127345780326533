import React from 'react';
import Toggle, { ToggleProps } from 'react-toggle';
import 'react-toggle/style.css';
import './toggle.scss';

const CustomToggle: React.FC<ToggleProps> = (props) => {
    return <Toggle icons={false} {...props}></Toggle>;
};

export default CustomToggle;
