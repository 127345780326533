import React from 'react';
import SimulationDigiTwin from './SimulationDigiTwin';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import { InputDataType } from '../SimulationMainView';
import SideBar, { Sidebarcomponent } from 'components/Sidebar/Sidebar';
import { useRoute } from 'wouter';
import { SimulationKPIsQueryData } from '_types/queries';
import './simulationDigiTwin.scss';
import { selectSimulationPeopleOnPlatform } from 'features/StationView/SimulationSlice';
import { SimulationDataManager } from 'dataManagers/simulationDataManager/simulationDataManager';

interface Props {
    simulationDataManager?: SimulationDataManager;
    inputData?: InputDataType;
}

const SimulationDigiTwinView: React.FC<Props> = (props) => {
    const [match, params] = useRoute('/simulations/:stationName/simulator/:simulationId');
    const stationName = params?.stationName ?? '';

    const { simulationDataManager } = props;

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    const simPeopleOnPlatform = useAppSelector(selectSimulationPeopleOnPlatform);

    return !simulationDataManager ? (
        <div></div>
    ) : (
        <>
            <SideBar stationName={stationName}>
                <Sidebarcomponent label={'Platform stats'}>
                    <div className="sidebar-vertical-split">
                        <div className="sim-platform-stats-container">
                            <label>People on platform</label>
                            <div>{simPeopleOnPlatform}</div>
                        </div>
                    </div>
                </Sidebarcomponent>
            </SideBar>
            <SimulationDigiTwin
                simulationDataManager={simulationDataManager}
                inputData={props.inputData}
            />
        </>
    );
};

export default SimulationDigiTwinView;
