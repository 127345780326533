import React from 'react';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import { useAppDispatch } from 'app/hooks';
import './DashBoard.scss';
import { useQuery } from '@apollo/client';
import { GETTOTALCOUNTSINOUT } from '_queries';
import { FaWalking } from 'react-icons/fa';
import PeopleFlowWeeklyChart from './DashboardGraphs/PeopleFlowWeeklyChart';
import PeopleFlowDailyChart from './DashboardGraphs/PeopleFlowDailyChart';
import PeopleFlowTrend from './DashboardGraphs/PeopleFlowTrend';
import EquipmentUsageChart from './DashboardGraphs/EquipmentUsageChart';
import MetroToWest from './DashboardGraphs/MetroToWestChart';
import MetroToEast from './DashboardGraphs/MetroToEastChart';
import moment from 'moment';
import { PeopleCountInOut } from '_types';

const DashBoard: React.FC = () => {
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    const peopleQuery = useQuery<PeopleCountInOut>(GETTOTALCOUNTSINOUT);
    React.useEffect(() => {
        peopleQuery.startPolling(600000);
        return () => peopleQuery.stopPolling();
    }, []);

    const peopleQueriedData = peopleQuery?.data?.getTotalCountsInOut[0];
    const [TotalPeople, setTotalPeople] = React.useState<string>('--');

    React.useEffect(() => {
        if (!peopleQueriedData) return;
        setTotalPeople(
            '' + peopleQueriedData.incoming_people_count + peopleQueriedData.outgoing_people_count
        );
    }, [peopleQueriedData]);

    return (
        <div id="dashboard-view">
            <div className="title-section">
                <div className="title">{moment().format('ddd, DD MMMM yyyy')}</div>
            </div>
            <div className="first-row-info">
                <div>People count:</div>
                <div className="people-count-wrapper">
                    {TotalPeople}
                    <FaWalking className="small-icon" />
                </div>
            </div>
            <div className="dashboard-body">
                <PeopleFlowDailyChart />
                <EquipmentUsageChart />
                <PeopleFlowWeeklyChart />
                <PeopleFlowTrend />
                <MetroToWest />
                <MetroToEast />
            </div>
        </div>
    );
};
export default DashBoard;
