import { Viewport } from './viewport';
import { StaticAssets } from './viz_staticAssets';
import { CameraAssets } from './viz_cameraAssets';
import { AnalysisAssets } from './viz_analysis';
import { AlertsAssets } from './viz_alertsAssets';
import { PeopleAssets } from './layers/peopleAssets';

export class Visualization {
    vpt: Viewport;
    static: StaticAssets;
    camera: CameraAssets;
    people: PeopleAssets;
    analysis: AnalysisAssets;
    alerts: AlertsAssets;

    constructor(
        vpt: Viewport,
        params: {
            envMapUrl: string;
            modelUrl: string;
        }
    ) {
        this.vpt = vpt;

        this.vpt.dependencies.set('visualization', this);

        this.static = new StaticAssets(vpt, params.envMapUrl);
        this.vpt.dependencies.set('static', this.static);
        this.analysis = new AnalysisAssets(vpt);
        this.vpt.dependencies.set('analysis', this.analysis);
        this.people = new PeopleAssets(vpt);
        this.vpt.dependencies.set('people', this.people);
        this.camera = new CameraAssets(vpt);
        this.vpt.dependencies.set('camera', this.camera);
        this.alerts = new AlertsAssets(vpt);
        this.vpt.dependencies.set('alerts', this.alerts);
    }
}
