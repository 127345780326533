import { useQuery } from '@apollo/client';
import { GETPEOPLECOUTDATABYMONTH } from '_queries';
import { PeopleFlowTrendType } from '_types';
import BarGraphWithAverage from 'components/Graphs/BarGraphWithAverage';
import moment from 'moment';
import React from 'react';

const PeopleFlowTrend: React.FC = () => {
    const query = useQuery<PeopleFlowTrendType>(GETPEOPLECOUTDATABYMONTH);
    /* React.useEffect(() => {
        query.startPolling(2000);

        return () => query.stopPolling();
    }, []); */

    const queriedData = query?.data?.MontlyDataPeople;
    const [data, setData] = React.useState<(number | null)[][]>([]);

    React.useEffect(() => {
        if (!queriedData) return;

        setData(
            queriedData.map((dayData) => [
                moment(new Date(dayData.event_date)).year(moment().year()).valueOf() / 1000,
                dayData.count,
            ])
        );
    }, [queriedData]);

    return (
        <BarGraphWithAverage
            title="People Flow trend"
            data={data}
            names={['Total passengers']}
            xAxisOption={{
                axisLabel: {
                    formatter: (n) => moment(n * 1000).format(`DD MMM`),
                },
                axisTick: {
                    alignWithLabel: true,
                },
            }}
            yAxisOption={{
                min: 0,
            }}
            seriesOption={{
                label: { show: false },
            }}
            tooltipOption={{
                trigger: 'item',
                formatter: (params) =>
                    moment(params.data[0] * 1000).format('DD MMM') + '</br>' + params.data[1],
            }}
            graphOption={{
                dataZoom: [
                    {
                        type: 'inside',
                    },
                    {
                        type: 'slider',
                        labelFormatter: (n) =>
                            data.length > 0
                                ? moment((data[0][0] as number) * 1000)
                                      .add(n, 'day')
                                      .format('DD MMM')
                                : null,
                    },
                ],
            }}
            loading={query.loading}
            error={!!query.error}
        />
    );
};

export default PeopleFlowTrend;
