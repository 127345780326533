import React from 'react';
import './statsSidebar.scss';
import { useAppSelector } from 'app/hooks';
import { selectMainView } from 'features/StationView/StationViewSlice';

import SidebarTitle from './SidebarTitle';
import LiveComponents from './LiveSidebarComponents';

interface StatsSidebarProps {
    stationName: string;
}

const StatsSidebar: React.FC<StatsSidebarProps> = (props: StatsSidebarProps) => {
    const mainView = useAppSelector(selectMainView);

    return (
        <div id="statsSidebar">
            <SidebarTitle {...props} />
            <div className="stats">
                <div className="statsContent">
                    {mainView === 'live' ? <LiveComponents /> : null}
                </div>
            </div>
        </div>
    );
};

export default StatsSidebar;
