import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

export const _AWS_Authenticate = async (params: {
    username: string;
    password: string;
}): Promise<CognitoUser> => {
    let user: CognitoUser;
    try {
        console.log('Signing in...');
        user = await Auth.signIn(params.username, params.password);
    } catch (error) {
        console.log('error signing in', error);
        throw error;
    }

    return user;
};

export const _AWS_Signout = async (): Promise<unknown> => {
    let signout;
    try {
        console.log('Signing out...');
        signout = await Auth.signOut();
    } catch (error) {
        console.log('error signing out!', error);
        throw error;
    }

    return signout;
};
