import React from 'react';
import { useRoute } from 'wouter';
import './parameters.scss';
import '../../Simulations/simulations.scss';
import { ParametersProps } from './ParametersView';
import { TimeFormatterFromSeconds } from 'components/util';
import Collapsible from 'react-collapsible';
import { NewSimulationTrigger } from 'components/Simulations/NewSimulation/NewSimulation';
import UsersSection, { UsersSectionState } from 'components/Simulations/NewSimulation/UsersSection';
import { FirstSectionGridElement } from 'components/Simulations/NewSimulation/FirstSection';
import { MetaDatatype } from '../SimulationMainView';
import ElevatorSection, {
    ElevatorsSectionState,
} from 'components/Simulations/NewSimulation/ElevatorSection';
import Divider from 'components/UIComponents/Divider/Divider';
import EscalatorSection, {
    EscalatorsSectionState,
} from 'components/Simulations/NewSimulation/EscalatorSection';
import { useGetSimulationAPIQuery } from 'app/store';
import _ from 'lodash';

const Parameters: React.FC<ParametersProps> = ({ inputData, metaData }) => {
    const [, params] = useRoute('/simulations/:stationName/simulator/:simulationId');
    const stationName = params?.stationName ?? '';

    const [usersSectionState, setUsersSectionState] = React.useState<UsersSectionState>();
    const [elevatorSectionState, setElevatorSectionState] = React.useState<ElevatorsSectionState>();
    const [escalatorSectionState, setEscalatorSectionState] =
        React.useState<EscalatorsSectionState>();

    const { data: schema } = useGetSimulationAPIQuery(`/${stationName.toLowerCase()}/schema`);

    React.useEffect(() => {
        if (!inputData) return;

        setElevatorSectionState(inputData.equipment.elevators);

        setEscalatorSectionState(inputData.equipment.escalators);
    }, [inputData]);

    React.useEffect(() => {
        if (!inputData || !schema) return;

        setUsersSectionState(getUserState(inputData.users, schema.properties.users));
    }, [inputData, schema]);

    return (
        <div id="new-simulation-view">
            <div className="page-title">{stationName}</div>
            <FirstLine metaData={metaData} time={inputData?.time} />
            <Divider />
            <Collapsible
                trigger={<NewSimulationTrigger open={false} label="Users" />}
                triggerWhenOpen={<NewSimulationTrigger open={true} label="Users" />}
                open={true}>
                <UsersSection
                    state={usersSectionState}
                    setState={() => console.log('no Changes')}
                    schema={schema?.properties.users}
                    active={false}
                />
            </Collapsible>
            {elevatorSectionState && escalatorSectionState && (
                <Collapsible
                    trigger={<NewSimulationTrigger open={false} label="Equipments" />}
                    triggerWhenOpen={<NewSimulationTrigger open={true} label="Equipments" />}
                    open={true}>
                    <div className="equipments-container">
                        <ElevatorSection
                            state={elevatorSectionState}
                            setState={() => console.log('no Changes')}
                            stationName={stationName}
                        />
                        <EscalatorSection
                            state={escalatorSectionState}
                            setState={() => console.log('no Changes')}
                            stationName={stationName}
                        />
                    </div>
                </Collapsible>
            )}
            {inputData?.schedules && (
                <Collapsible
                    trigger={<NewSimulationTrigger open={false} label="Time Intervals" />}
                    triggerWhenOpen={<NewSimulationTrigger open={true} label="Time Intervals" />}
                    open={true}>
                    <div className="schedules-container">
                        {Object.entries(inputData?.schedules).map(([metroDirection, value]) => (
                            <div key={metroDirection} className="schedules-side">
                                <span className="schedule-side-title">
                                    {metroDirection.split('_')[0] + ' side'}
                                </span>
                                <div className="schedules-elements">
                                    <div className="schedules">
                                        <span className="schedules-title">
                                            First train (hh:mm:ss)
                                        </span>
                                        <div className="time-displayer">
                                            {TimeFormatterFromSeconds(value.start, true)}
                                        </div>
                                    </div>
                                    <div className="schedules">
                                        <span className="schedules-title">
                                            Interval between trains (mm:ss)
                                        </span>
                                        <div className="time-displayer">
                                            {TimeFormatterFromSeconds(value.time_interval, true)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            )}
        </div>
    );
};

export default Parameters;

const FirstLine: React.FC<{ metaData?: MetaDatatype; time?: { start: number; end: number } }> = ({
    metaData,
    time,
}) => {
    let dayOfWeek = metaData?.dayOfWeek ?? '';
    if (!metaData?.dayOfWeek && metaData?.name) {
        if (metaData.name.toLowerCase().includes('monday')) dayOfWeek = 'monday';
        else if (metaData.name.toLowerCase().includes('tuesday')) dayOfWeek = 'tuesday';
        else if (metaData.name.toLowerCase().includes('wednesday')) dayOfWeek = 'wednesday';
        else if (metaData.name.toLowerCase().includes('thursday')) dayOfWeek = 'thursday';
        else if (metaData.name.toLowerCase().includes('friday')) dayOfWeek = 'friday';
        else if (metaData.name.toLowerCase().includes('saturday')) dayOfWeek = 'saturday';
        else if (metaData.name.toLowerCase().includes('sunday')) dayOfWeek = 'sunday';
    }

    return (
        <div className="grid-3">
            {metaData?.name && (
                <FirstSectionGridElement label={'Simulation name'}>
                    <div className="mock-selector">{metaData.name}</div>
                </FirstSectionGridElement>
            )}
            {dayOfWeek.length > 0 && time && (
                <>
                    <FirstSectionGridElement label={'Day of the week'}>
                        <div className="mock-selector day-of-week">{dayOfWeek}</div>
                    </FirstSectionGridElement>
                    <FirstSectionGridElement label={'Time of the day'}>
                        <div className="mock-selector">
                            {TimeFormatterFromSeconds(time.start * 3600) +
                                ' - ' +
                                TimeFormatterFromSeconds(time.end * 3600)}
                        </div>
                    </FirstSectionGridElement>
                </>
            )}
        </div>
    );
};

const getUserState = (usersSectionState: UsersSectionState, schema: Record<string, unknown>) => {
    const result = _.cloneDeep(usersSectionState);
    const iterate = (schemaObj, path = '') => {
        if (schemaObj.type === 'number' && schemaObj.maximum === 100) {
            const splitPath = path.split('/').slice(1);
            if (_.get(usersSectionState, splitPath) < 1)
                _.set(result, splitPath, Math.round(_.get(result, splitPath) * 100));
        } else if (schemaObj.type === 'object') {
            Object.entries(schemaObj.properties).forEach(([k, v]) => iterate(v, path + '/' + k));
        }
    };
    iterate(schema);
    return result;
};
