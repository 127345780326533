import React from 'react';
import { ReactComponent as OfficeIcon } from '../../assets/icons/office-building-2.svg';
import { useAppSelector } from 'app/hooks';
import { selectCurrentCameraView } from 'features/StationView/StationViewSlice';

interface StatsSidebarProps {
    stationName: string;
}

const SidebarTitle: React.FC<StatsSidebarProps> = (props: StatsSidebarProps) => {
    const { stationName } = props;

    const activeView = useAppSelector(selectCurrentCameraView);

    return (
        <>
            <div className="title">
                <h2>
                    <OfficeIcon />
                    {stationName}
                </h2>
                <h3>{activeView.label}</h3>
            </div>
        </>
    );
};

export default SidebarTitle;
