import { w3cwebsocket as ws } from 'websocket';

export default class WsClients {
    clients: WebSocket[] = [];

    constructor() {
        this.Open();
    }

    public Open() {
        this.clients = [];
        this.clients.push(new ws(process.env.REACT_APP_WEBSOCKET_URL));

        this.clients.forEach(
            (client, idx) => (client.onopen = () => console.log(`Websock ${idx + 1} is connected`))
        );
        this.clients.forEach(
            (client, idx) => (client.onclose = () => console.log(`Websock ${idx + 1} is closed`))
        );
    }

    public Close() {
        this.clients.forEach((client) => client.close());
    }
}
