import { useQuery } from '@apollo/client';
import { GETPEOPLEANDBIKECOUNTDATAWEEKLY } from '_queries';
import { PeopleCountWeekly } from '_types';
import BarGraphWithAverage from 'components/Graphs/BarGraphWithAverage';
import moment from 'moment';
import React from 'react';

const PeopleFlowWeeklyChart: React.FC = () => {
    const query = useQuery<PeopleCountWeekly>(GETPEOPLEANDBIKECOUNTDATAWEEKLY);
    /* React.useEffect(() => {
        query.startPolling(2000);

        return () => query.stopPolling();
    }, []); */

    const queriedData = query.data?.weeklyDatapeople.human;
    const [data, setData] = React.useState<(number | null)[][]>([]);

    React.useEffect(() => {
        if (!queriedData) return;

        const monday = moment().startOf('week').add(1, 'day');
        const averages = queriedData.this_week_data;
        const values = queriedData.weekly_avg_data;
        setData([
            [monday.valueOf() / 1000, values.Monday, averages.Monday],
            [monday.add(1, 'day').valueOf() / 1000, values.Tuesday, averages.Tuesday],
            [monday.add(1, 'day').valueOf() / 1000, values.Wednesday, averages.Wednesday],
            [monday.add(1, 'day').valueOf() / 1000, values.Thursday, averages.Thursday],
            [monday.add(1, 'day').valueOf() / 1000, values.Friday, averages.Friday],
            [monday.add(1, 'day').valueOf() / 1000, values.Saturday, averages.Saturday],
            [monday.add(1, 'day').valueOf() / 1000, values.Sunday, averages.Sunday],
        ]);
    }, [queriedData]);

    return (
        <BarGraphWithAverage
            title="People Flow for this week"
            data={data}
            names={['Total passengers', 'Average']}
            xAxisOption={{
                type: 'value',
                min: moment().startOf('week').valueOf() / 1000,
                max: moment().startOf('week').add(8, 'day').valueOf() / 1000,
                interval: 86400, // one day in seconds
                axisLabel: {
                    formatter: (n) => moment(n * 1000).format(`dddd`),
                    rotate: 45,
                    interval: 2,
                    showMaxLabel: false,
                    showMinLabel: false,
                },
            }}
            yAxisOption={{
                min: 0,
            }}
            tooltipOption={{
                trigger: 'item',
                formatter: (params) =>
                    params.seriesName + '</br>' + params.data[params.seriesIndex + 1],
            }}
            loading={query.loading}
            error={!!query.error}
        />
    );
};

export default PeopleFlowWeeklyChart;
