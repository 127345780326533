import {
    ApolloClient,
    NormalizedCacheObject,
    ObservableQuery,
    OperationVariables,
} from '@apollo/client';
import { GETEQUIPMENTS } from '_queries';
import { apolloClient } from 'app/clients/apolloClient';
import { Visualization } from 'components/Visualizer/visualization';
import { AssetData } from '_types';
import { EquipmentData, ElevatorEventList, EscalatorEventList } from '_types/queries';
import { ElevatorListItemToAssetDataArray, EscalatorListItemToAssetData } from '../util';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';
import { LDM } from './liveDataManager';
import { MetadataEquipments } from '_types/features';

export class EquipmentDataManager implements LDM {
    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;
    query: ObservableQuery<EquipmentData, OperationVariables>;
    assets: AssetData[];
    assetSubscription: Subscription;
    equipments: MetadataEquipments;

    constructor(equipments: MetadataEquipments) {
        this.equipments = equipments;

        this.client = apolloClient;
        this.assets = [];
        this.SetQuery(0);
    }

    private SetQuery(pollInterval: number) {
        this.query = this.client.watchQuery({
            query: GETEQUIPMENTS,
            pollInterval,
        });
    }

    public SetMainViewSubscription(viz: Visualization, pollInterval: number) {
        this.viz = viz;

        this.SetQuery(pollInterval);
        const subscribption = this.query.subscribe({
            next: this.SubscribeFunctionMainView.bind(this),
            error: (e) => console.error(e),
        });
        this.assetSubscription = subscribption;
    }

    private SubscribeFunctionMainView({
        data: { listElevatorEvents, listEscalatorEvents },
    }: {
        data: EquipmentData;
    }) {
        const elevatorEventList: ElevatorEventList | undefined = listElevatorEvents?.items;
        const escalatorEventList: EscalatorEventList | undefined = listEscalatorEvents?.items;

        let ElevatorsAsset: AssetData[] = [];
        if (elevatorEventList)
            ElevatorsAsset = this.SubscribeFunctionElevatorsMainView(elevatorEventList);
        let EscalatorAsset: AssetData[] = [];
        if (escalatorEventList)
            EscalatorAsset = this.SubscribeFunctionEscalatorMainView(escalatorEventList);

        this.assets = [...ElevatorsAsset, ...EscalatorAsset];

        this.viz.static.SetAssetsFeatures(this.assets);
    }

    private SubscribeFunctionElevatorsMainView(elevatorEventList: ElevatorEventList) {
        const elevatorAsset = (
            elevatorEventList
                .map((elevator) => {
                    const elevatorAsset = this.equipments.elevators.find(
                        (e) => +e.id === elevator.EquipmentNumber
                    );
                    return ElevatorListItemToAssetDataArray(elevatorAsset, elevator);
                })
                .filter((o) => !!o) as AssetData[][]
        ).flat();

        return elevatorAsset;
    }

    private SubscribeFunctionEscalatorMainView(escalatorEventList: EscalatorEventList) {
        const escalatorsAssets: AssetData[] = escalatorEventList
            .map((escalator) => {
                const escalatorAsset = this.equipments.escalators.find(
                    (e) => e.id === escalator.EquipmentNumber
                );
                return EscalatorListItemToAssetData(escalatorAsset, escalator);
            })
            .filter((o) => !!o) as AssetData[];

        return escalatorsAssets;
    }

    public StopMainViewSubscription() {
        if (!this.assetSubscription) return;
        this.assetSubscription.unsubscribe();
    }
}
