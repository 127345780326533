import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as CloseCross } from 'assets/icons/close-cross.svg';
import { ReactComponent as AlertTriangolFilledIcon } from 'assets/icons/alert-triangle-filled.svg';
import { ReactComponent as WarningCircleFilledIcon } from 'assets/icons/warning-circle-filled.svg';
import { ReactComponent as CheckCircleFilledIcon } from 'assets/icons/check-circle-filled.svg';
import './alertToaster.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'components/UIComponents/Button/Button';

export const AlertToasterContainer: React.FC = () => {
    return (
        <ToastContainer
            // closeButton={({ closeToast }) => (
            //     <div onClick={closeToast}>
            //         <CloseCross fill="white" style={{ cursor: 'pointer' }} />
            //     </div>
            // )}
            closeButton={false}
            limit={3}
            position="top-right"
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeOnClick={false}
        />
    );
};

type Props = React.PropsWithChildren<{
    closeToast?: () => void;
    severity: 1 | 2 | 3;
    title: string;
    alertOptions: { timestamp: string; site: string };
    onInvestigate?: () => void;
}>;

export const AlertToaster: React.FC<Props> = (props) => {
    const { severity, title, children, closeToast, onInvestigate } = props;

    const color = alertsColor(severity);

    /* const [videoMonitoringAlertStatusMutation] = useMutation(SETVIDEOMONITORINGALERTSREAD);

    const userInteracts = () => {
        videoMonitoringAlertStatusMutation({
            variables: {
                time_stamp: alertOptions.timestamp,
                site_id: alertOptions.site,
            },
        });
    }; */
    const closePopUp = () => {
        if (closeToast) closeToast();
    };

    return (
        <div
            className="notification-pop-up"
            style={{ borderColor: color, fill: color }}
            // onClick={() => userInteracts()}
        >
            <div className="pop-up-top">
                <AlertsIcon severity={severity} />
                <div className="pop-up-main">
                    <div className="pop-up-title">{title}</div>
                    <div className="pop-up-text-box">{children}</div>
                </div>
                <CloseCross fill="white" onClick={() => closePopUp()} />
            </div>
            <div className="pop-up-bottom">
                {/* <Button className="secondary"></Button> */}
                {onInvestigate && (
                    <Button
                        onClick={() => {
                            onInvestigate();
                            closePopUp();
                        }}>
                        Investigate
                    </Button>
                )}
            </div>
        </div>
    );
};

export const AlertsIcon: React.FC<{ severity: 1 | 2 | 3 }> = ({ severity }) => {
    return severity === 3 ? (
        <AlertTriangolFilledIcon />
    ) : severity === 2 ? (
        <WarningCircleFilledIcon />
    ) : (
        <CheckCircleFilledIcon />
    );
};

export const alertsColor = (severity: 1 | 2 | 3) => {
    return severity === 3
        ? 'var(--color-accent-red)'
        : severity === 2
        ? 'var(--color-accent-orange)'
        : 'var(--color-accent-green)';
};
