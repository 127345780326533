import { CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { Viewport } from './viewport';
import * as THREE from 'three';
import { renderToString } from 'react-dom/server';
import {
    AlertsTooltipArrow,
    // AlertsTooltipButton,
    AlertsTooltipCloseIcon,
    AlertsTooltipIcon,
} from 'components/AlertTooltip/AlertTooltip';
import '../AlertTooltip/alertTooltip.scss';
import { ObjPosition } from '_types';
import { store } from 'app/store';
import { deleteAlertPanel } from 'features/StationView/AlertSlice';

type AlertPanelsType = Record<
    string,
    { position: ObjPosition; content: string; severity: 1 | 2 | 3 }
>;

export class AlertsAssets {
    vpt: Viewport;
    scene: THREE.Group;
    api: {
        AddPanels: (alertsPanels: AlertPanelsType) => void;
    };

    constructor(vpt: Viewport) {
        this.vpt = vpt;

        this.scene = new THREE.Group();
        this.scene.name = 'StaticScene';
        vpt.scene.add(this.scene);

        this.api = { AddPanels: this.AddPanels };
    }

    private AddPanels(alertsPanels: AlertPanelsType) {
        if (this.scene) {
            this.scene.traverse((obj: THREE.Object3D) => {
                if ((obj as unknown as Record<string, unknown>).isCSS3DSprite) {
                    this.scene.remove(obj);
                }
            });
        }

        Object.entries(alertsPanels).forEach(([id, panel]) =>
            this.CreatePanel(id, panel.position, panel.content, panel.severity)
        );
    }

    private CreatePanel(id: string, position: ObjPosition, content: string, severity: 1 | 2 | 3) {
        const color =
            severity === 3
                ? 'var(--color-accent-red)'
                : severity === 2
                ? 'var(--color-accent-orange)'
                : 'var(--color-accent-green)';

        const element = document.createElement('div');
        element.className = 'alert-tooltip';

        const tooltipMain = document.createElement('div');
        tooltipMain.className = 'tooltip-main';
        tooltipMain.style.backgroundColor = color;
        element.appendChild(tooltipMain);

        const tooltipBody = document.createElement('div');
        tooltipBody.className = 'tooltip-body';
        tooltipMain.appendChild(tooltipBody);

        const icon = document.createElement('div');
        icon.innerHTML = renderToString(AlertsTooltipIcon(severity));
        tooltipBody.appendChild(icon);

        const tooltipTextBox = document.createElement('div');
        tooltipTextBox.className = 'tooltip-text-box';
        tooltipTextBox.innerText = content;
        tooltipBody.appendChild(tooltipTextBox);

        const closeIcon = document.createElement('div');
        closeIcon.style.cursor = 'pointer';
        closeIcon.innerHTML = renderToString(AlertsTooltipCloseIcon());
        closeIcon.onpointerdown = () => {
            store.dispatch(deleteAlertPanel(id));
        };
        tooltipBody.appendChild(closeIcon);

        /* const tooltipButtonWrapper = document.createElement('div');
        tooltipButtonWrapper.className = 'tooltip-button-wrapper';
        tooltipMain.appendChild(tooltipButtonWrapper);

        const closeButton = document.createElement('div');
        closeButton.innerHTML = renderToString(AlertsTooltipButton());
        closeButton.onpointerdown = () => {
            store.dispatch(deleteAlertPanel(id));
        };
        tooltipButtonWrapper.appendChild(closeButton); */

        const arrowIcon = document.createElement('div');
        arrowIcon.style.lineHeight = '0';
        arrowIcon.innerHTML = renderToString(AlertsTooltipArrow(color));
        element.appendChild(arrowIcon);

        const cssSprite = new CSS3DSprite(element);

        cssSprite.scale.set(0.05, 0.05, 0.05);
        cssSprite.position.set(position.x, position.y, position.z);

        this.scene.add(cssSprite);
    }
}
